import { Decipher } from "/src/js/classes/Decipher"
/*  */
new Decipher({
    target: document.querySelector("h2 span"),
    auto: true,
    autoCooldown: 3000,
    cooldown: 40,
    attempts: 3,
    words: [
        "Web Developer",
        "UI/UX Designer",
        "Game Developer",
        "NFT Creator",
        "Team Leader",
    ],
})
